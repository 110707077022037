import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import logo from './images/RSG.png';
import './App.css';

function App() {

  // Fetch Nav
  const [navMenuData, setNavMenuData] = useState({ navMenuData: {} });
  useEffect(() => {
    const fetchNavMenu = async () => {
      const result = await axios(
        'https://riverstreetgardens.com:8080/navigation-menu'
      );
      console.log('nav fetch', result);
      setNavMenuData(result.data);
    };

    fetchNavMenu();
  }, []);

  const navLinks = navMenuData.navmenu
    ? navMenuData.navmenu.map((link) => {
        const to = link.name === 'Home' ? '/' : link.name;
        return (
          <li className="link" key={link.id}>
            <Link to={to.toLowerCase()}>{link.name}</Link>
          </li>
        );
      })
    : null;

  // Fetch Homepage data
  const [homepage, setHomepage] = useState({ homepage: {} });
  useEffect(() => {
    const fetchHomepage = async () => {
      const result = await axios('https://riverstreetgardens.com:8080/home');
      console.log(result);
      setHomepage(result.data);
    };

    fetchHomepage();
  }, []);

  // Fetch Contact data
  const [contact, setContact] = useState({ contact: {} });
  useEffect(() => {
    const fetchContact = async () => {
      const result = await axios('https://riverstreetgardens.com:8080/contact');
      console.log(result);
      setContact(result.data);
    };

    fetchContact();
  }, []);

  // Fetch Products data
  const [products, setProducts] = useState({ products: {} });
  useEffect(() => {
    const fetchProducts = async () => {
      const result = await axios('https://riverstreetgardens.com:8080/products');
      console.log(result);
      setProducts(result.data);
    };

    fetchProducts();
  }, []);

  // Fetch About data
  const [about, setAbout] = useState({ about: {} });
  useEffect(() => {
    const fetchAbout = async () => {
      const result = await axios('https://riverstreetgardens.com:8080/about');
      console.log(result);
      setAbout(result.data);
    };

    fetchAbout();
  }, []);

  const IndexPage = () => {
    return (
      <div className="content">
        <ReactMarkdown source={homepage.content} escapeHtml={false} />
      </div>
    );
  };

  const ContactPage = () => {
    return (
      <div className="content">
        <ReactMarkdown source={contact.content} escapeHtml={false} />
      </div>
    );
  };

  const ProductsPage = () => {
    return (
      <div className="content">
        <ReactMarkdown source={products.Content} escapeHtml={false} />
      </div>
    );
  };

  const AboutPage = () => {
    return (
      <div className="content">
        <ReactMarkdown source={about.Content} escapeHtml={false} />
      </div>
    );
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <nav className="nav">
          <ul>{navLinks}</ul>
        </nav>
        <Route path="/" exact component={IndexPage} />
        <Route path="/contact" exact component={ContactPage} />
        <Route path="/products" exact component={ProductsPage} />
        <Route path="/about" exact component={AboutPage} />
      </div>
    </Router>
  );
}

export default App;
